.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder *, .mapboxgl-ctrl-geocoder :after, .mapboxgl-ctrl-geocoder :before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  min-width: 240px;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  font-family: Open Sans, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  transition: width .25s, min-width .25s;
  position: relative;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  height: 50px;
  color: #404040;
  color: #000000bf;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 6px 45px;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none;
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040;
  color: #000000bf;
  box-shadow: none;
  outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  display: none;
  position: absolute;
  top: 7px;
  right: 8px;
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px #0000001a;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder .suggestions {
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  list-style: none;
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  overflow: hidden;
}

.mapboxgl-ctrl-bottom-left .suggestions, .mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  color: #404040;
  padding: 6px 12px;
  display: block;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a, .mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #404040;
  cursor: pointer;
  background-color: #f3f3f3;
  text-decoration: none;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title, .mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--icon {
  vertical-align: middle;
  speak: none;
  fill: #757575;
  display: inline-block;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 13px;
  left: 12px;
}

.mapboxgl-ctrl-geocoder--button {
  cursor: pointer;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0;
  animation: rotate .8s cubic-bezier(.45, .05, .55, .95) infinite;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder {
    width: 33.3333%;
    max-width: 360px;
    font-size: 15px;
    line-height: 20px;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    width: 20px;
    height: 20px;
    left: 7px;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: #909090;
    text-align: center;
    padding: 6px 12px;
    font-size: 16px;
  }
}

/*# sourceMappingURL=index.ff67c549.css.map */
